const get = key => {
  return import.meta.env[key];
};

const isLocal = () => get('VITE_ENV') === 'local';
const isDev = () => get('VITE_ENV') === 'dev';

const isProduction = () => !isLocal() && !isDev();

export default {
  get,
  isLocal,
  isDev,
  isProduction,
};
