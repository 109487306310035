import * as Sentry from '@sentry/vue';

import env from '@shared/utilities/environment';

// importing the router causes a circular dependency, capture an instance of it when we initialize in main.js
let capturedRouter = null;

const shouldInitialize = () => {
  // we load the app in an iframe to initialize google tag manager, don't initialize sentry a second time
  const isIframe = window.self !== window.top;
  return !env.isLocal() && !isIframe;
};

export const init = ({ app, router, store }) => {
  if (!shouldInitialize()) return;

  capturedRouter = router;

  Sentry.init({
    app,
    dsn: 'https://a0eebe7c6f96482db218a83f202bcb2f@o231953.ingest.sentry.io/1392759',
    environment: env.get('VITE_ENV'),
    release: env.get('VITE_CONSOLE_VERSION'),
    tracesSampleRate: 0.2, // 20% of transactions will be sent to Sentry
    profilesSampleRate: 1.0, // 100% of the tracesSampleRate will have profiling data
    integrations: [],
    tracePropagationTargets: [
      'localhost',
      // we don't want to match login.dev.prosperops.com or it will cause errors
      /^https:\/\/(admin|api|console)\.(?:dev\.)?prosperops\.(com|net)\/.*/,
    ],
    ignoreErrors: [
      '[EMAIL_VERIFICATION_REQUIRED]',
      'LoginRequiredError',
      'NavigationDuplicated',
      /Navigation cancelled from .+ to "\/session_ended" with a new navigation./,
      /AxiosError/,
    ],
    replaysSessionSampleRate: 0.2, // 20% of sessions will be recorded
    replaysOnErrorSampleRate: 1.0, // Sentry buffers replay events, if an error occurs we always sample it
  });

  Sentry.configureScope(scope => {
    scope.setExtra(store.state);
  });
};

let integrationsAdded = false;

export const setUser = (user, roles) => {
  if (!shouldInitialize()) return;
  if (roles.includes('ProsperOpsCanary')) return;

  if (!integrationsAdded) {
    integrationsAdded = true;

    Sentry.addIntegration(Sentry.browserTracingIntegration({ router: capturedRouter }));

    const replayIntegration = Sentry.replayIntegration({
      networkDetailAllowUrls: [/.*/],
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
      beforeAddRecordingEvent,
    });

    Sentry.addIntegration(replayIntegration);

    Sentry.addIntegration(Sentry.BrowserProfilingIntegration());
  }

  const { sub: id, ...rest } = user;
  Sentry.setUser({
    id,
    username: rest.email,
    ...rest,
  });
};

const beforeAddRecordingEvent = event => {
  const { tag, payload } = event.data;

  const isNetworkRequest =
    tag === 'performanceSpan' && (payload.op === 'resource.fetch' || payload.op === 'resource.xhr');

  if (isNetworkRequest) {
    const eventUrl = payload.description;
    const sensitiveUrls = ['oauth/token', 'hubspot_token'];
    if (sensitiveUrls.some(s => eventUrl.includes(s))) {
      return null;
    }
  }

  return event;
};

// There is no official Vuex plugin so we need to add a custom implementation
export const vuexPlugin = store => {
  store.subscribe(mutation => {
    const message = 'Vuex Mutation';
    const input = [message, mutation];
    const level = 'log';

    const breadcrumb = {
      category: 'console',
      data: {
        arguments: input,
        logger: 'console',
      },
      level,
      message: message,
    };

    Sentry.addBreadcrumb(breadcrumb, {
      input,
      level,
    });
  });
};

export default Sentry;
