import type { User } from '@auth0/auth0-spa-js';
import type { CustomerId } from '@shared/services/namedIds';

import _ from 'lodash';
import { defineStore } from 'pinia';

import Sentry, { setUser as setSentryUser } from '@console/lib/sentry';
import authService from '@console/services/authService';
import { createSession, getHubSpotToken, deleteSession } from '@shared/services/customer';
import { isDefined } from '@shared/utilities/is_defined';

export enum Cloud {
  AWS = 'aws',
  GCP = 'gcp',
  AZURE = 'azure',
}

export enum PermissionLevel {
  Viewer = 'Viewer',
  Editor = 'Editor',
  Owner = 'Owner',
}

export class CustomerRole {
  constructor(public customerId: CustomerId, public permission: PermissionLevel) {}
  toRoleString() {
    return `${this.customerId}:${this.permission}`;
  }
}

export class GranularRole {
  constructor(
    public customerId: CustomerId,
    public cloud: Cloud,
    public resourceId: string,
    public permission: PermissionLevel
  ) {}

  toRoleString() {
    return `${this.customerId}:${this.cloud}:${this.resourceId}:${this.permission}`;
  }
}

export function convertCloudFromApiResponse(cloud: string) {
  switch (cloud) {
    case 'aws':
      return Cloud.AWS;
    case 'gcp':
      return Cloud.GCP;
    case 'azure':
      return Cloud.AZURE;
    default:
      throw new Error(`Invalid cloud: ${cloud}`);
  }
}

export function convertPermissionLevelFromApiResponse(permissionLevel: string) {
  switch (permissionLevel) {
    case 'Owner':
      return PermissionLevel.Owner;
    case 'Editor':
      return PermissionLevel.Editor;
    case 'Viewer':
      return PermissionLevel.Viewer;
    default:
      throw new Error(`Invalid permissionLevel: ${permissionLevel}`);
  }
}

interface AuthStore {
  profile: {
    name: string;
    email: string;
    sub: string;
    claims: {
      roles: string[];
      isFederated: boolean;
      isSocial: boolean;
      isEmployee: boolean;
      isForcedDemo: boolean;
      createdAt: string;
    };
  };
  accessToken: string;
  hubSpotToken: string;
}

export const useAuthStore = defineStore('auth', {
  state: (): AuthStore => ({
    profile: {
      name: '',
      email: '',
      sub: '',
      claims: {
        roles: [],
        isFederated: false,
        isSocial: false,
        isEmployee: false,
        isForcedDemo: false,
        createdAt: '',
      },
    },
    accessToken: '',
    hubSpotToken: '',
  }),
  getters: {
    loggedIn(state) {
      return !!state.profile;
    },
    fullName(state) {
      return state.profile.name;
    },
    email(state) {
      return state.profile.email;
    },
    isFederated(state) {
      return state.profile.claims.isFederated;
    },
    isSocial(state) {
      return state.profile.claims.isSocial;
    },
    isGranularUser: () => (customerId: CustomerId) => {
      const authStore = useAuthStore();
      return authStore.allUserRolesByCustomer[customerId]?.some(r => r instanceof GranularRole);
    },
    allUserRolesByCustomer(): Record<string, Array<CustomerRole | GranularRole>> {
      const authStore = useAuthStore();
      return authStore.allUserRoles.reduce(
        (rolesByCustomerMap: Record<string, Array<CustomerRole | GranularRole>>, role: CustomerRole | GranularRole) => {
          if (!rolesByCustomerMap[role.customerId]) {
            rolesByCustomerMap[role.customerId] = [];
          }
          rolesByCustomerMap[role.customerId].push(role);
          return rolesByCustomerMap;
        },
        {}
      );
    },
    allUserRoles(state): Array<CustomerRole | GranularRole> {
      return _.chain(state.profile.claims.roles)
        .filter((r: string) => r.includes(':'))
        .map((r: string) => {
          const splitRole = r.split(':');
          if (splitRole.length === 2) {
            return new CustomerRole(splitRole[0], convertPermissionLevelFromApiResponse(splitRole[1]));
          }
          if (splitRole.length === 4) {
            return new GranularRole(
              splitRole[0],
              convertCloudFromApiResponse(splitRole[1]),
              splitRole[2],
              convertPermissionLevelFromApiResponse(splitRole[3])
            );
          }
        })
        .filter(isDefined)
        .value();
    },
    hasRoles(): boolean {
      return !_.isEmpty(this.allUserRoles) || this.isEmployee;
    },
    isAtLeastRoleForCustomerOrGranular: () => (
      customerId: CustomerId,
      minimumPermissionLevel: PermissionLevel,
      cloud: Cloud,
      resourceId: string
    ): boolean => {
      // cloud and resourceid are only used for granular roles. This works differently than isAtLeastRole which
      // will return the highest permission level for the customer even from granular roles for any cloud/resource.
      // This function will instead specifically check cloud and resource id from the granular roles for
      // specific use cases where we need to check for a specific resource.
      const authStore = useAuthStore();
      const role = authStore.allUserRolesByCustomer[customerId]?.find(
        r =>
          (r instanceof GranularRole && r.cloud === cloud && r.resourceId === resourceId) || r instanceof CustomerRole
      );

      return role ? authStore.isAtLeastPermissionLevel(minimumPermissionLevel, role.permission) : false;
    },
    isAtLeastRole: () => (customerId: CustomerId, minimumRole: PermissionLevel): boolean => {
      const authStore = useAuthStore();

      const currentRole = authStore.role(customerId);
      if (!currentRole) return false;

      return authStore.isAtLeastPermissionLevel(minimumRole, currentRole);
    },
    isAtLeastPermissionLevel: () => (minimumPermissionLevel: PermissionLevel, rolePermissionLevel: PermissionLevel) => {
      const roles = { Viewer: 1, Editor: 2, Owner: 3 };
      const minimumLevel = roles[minimumPermissionLevel];

      if (!minimumLevel) {
        throw new Error(`Invalid minimum role: ${minimumPermissionLevel}`);
      }

      return roles[rolePermissionLevel] >= minimumLevel;
    },
    // For granular users, this will return the highest level permission they have for the customer
    // meaning if they had 3 roles for a customer, 2 were viewer and 1 editor they would get editor.
    role: () => (customerId: CustomerId): PermissionLevel | undefined => {
      const authStore = useAuthStore();
      if (authStore.isEmployee) {
        return PermissionLevel.Owner;
      }
      const allUserRoles: Array<CustomerRole | GranularRole> = authStore.allUserRoles;
      let highestRolePermission;
      for (const role of allUserRoles) {
        if (role.customerId === customerId) {
          if (role instanceof CustomerRole) {
            // there shouldnt be any other customer roles than the one
            // so return immediately with it
            return role.permission;
          }

          if (role instanceof GranularRole) {
            // if editor, return immediately with it
            if (role.permission === PermissionLevel.Editor) {
              return role.permission;
            }
            // if viewer keep track of it, but continue looking for editor
            if (role.permission === PermissionLevel.Viewer) {
              highestRolePermission = role.permission;
            }
          }
        }
      }
      return highestRolePermission;
    },
    isEmployee(state) {
      return state.profile.claims.isEmployee;
    },
    isEmployeeDemo(state) {
      return state.profile.claims.isForcedDemo;
    },
  },
  actions: {
    setProfile(auth0User: User) {
      this.profile = {
        name: auth0User.name || '',
        email: auth0User.email || '',
        sub: auth0User.sub || '',
        claims: {
          roles: auth0User['https://prosperops.com/roles'] || [],
          isFederated: auth0User['https://prosperops.com/is_federated'] || false,
          isSocial: auth0User['https://prosperops.com/is_social'] || false,
          isEmployee: auth0User['https://prosperops.com/is_employee'] || false,
          isForcedDemo: auth0User['https://prosperops.com/is_forced_demo'] || false,
          createdAt: auth0User['https://prosperops.com/created_at'] || '',
        },
      };
    },
    async authenticateFromCallback() {
      const redirectTo = await authService.handleAuthenticationRedirect();
      await createSession();
      await this.refresh();
      return redirectTo;
    },
    async refresh() {
      const accessToken = await authService.getToken();
      let initMonitoring = false;
      if (this.accessToken !== accessToken) {
        this.setProfile(await authService.getUser());
        this.accessToken = accessToken;
        initMonitoring = true;
      }
      await this.refreshHubSpotToken();
      if (initMonitoring) {
        await this.setupMonitoring();
      }
    },
    async refreshHubSpotToken() {
      if (this.isEmployee || this.hubSpotToken) {
        return;
      }
      try {
        const { data } = await getHubSpotToken();
        this.hubSpotToken = data.token;
      } catch (e) {
        // Intentionally tracking and suppressing errors to prevent
        // showing user the ISE page for any HubSpot errors.
        Sentry.captureException(e);
      }
    },
    async refreshRoles() {
      const accessToken = await authService.getToken({ ignoreCache: true });
      this.accessToken = accessToken;
      this.setProfile(await authService.getUser());
    },
    async logOut() {
      await deleteSession();
      authService.logout();
    },
    setupMonitoring() {
      const user = this.profile;
      const roles = this.profile.claims.roles;
      if (!user || !roles) return;

      setSentryUser(user, roles);
    },
  },
});
