// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import _ from 'lodash';

// required for method A
import { useAzureStore } from '@azure/state';
import * as mask from '@console/lib/mask';
import customerService from '@console/services/customerService';
import analytics from '@shared/lib/analytics';
import storage from '@shared/lib/local_storage';
import { setCustomerId } from '@shared/services/api';
import { useAuthStore } from '@shared/state/auth.store';

const maskCustomers = customers => {
  return _.map(customers, customer => {
    return _.assign({}, customer, {
      company_name: mask.generateCustomerName(customer.id),
    });
  });
};

const state = {
  customers: {},
  selectedCompanyId: storage.get('customers.selectedCompanyId'),
  federationContext: storage.get('customers.federationContext'),
  customerSettings: [],
};

const mutations = {
  RESET_CUSTOMER(state) {
    state.customers = {};
    state.selectedCompanyId = null;
    storage.remove('customers.selectedCompanyId');
    state.federationContext = null;
    storage.remove('customers.federationContext');
  },
  SET_SUBSCRIBED(state) {
    state.customers[state.selectedCompanyId].active_subscription = true;
  },
  SET_CUSTOMERS(state, customers) {
    state.customers = _.keyBy(customers, 'id');
  },
  SET_SELECTED_COMPANY_ID(state, selectedCompanyId) {
    state.selectedCompanyId = selectedCompanyId;
    storage.set('customers.selectedCompanyId', selectedCompanyId);
    customerService.setCustomerId(selectedCompanyId);
    setCustomerId(selectedCompanyId);
  },
  SET_FEDERATION_CONTEXT(state, federationContext) {
    state.federationContext = federationContext;
    storage.set('customers.federationContext', federationContext);
  },
  SET_CUSTOMER_SETTINGS(state, customerSettings) {
    state.customerSettings = customerSettings;
  },
};

const getters = {
  selectedCompany(state) {
    return state.customers[state.selectedCompanyId];
  },
  companyName(state, getters) {
    return _.get(getters.selectedCompany, 'company_name');
  },
  isSubscribed(state, getters) {
    return _.get(getters.selectedCompany, 'active_subscription');
  },
  incrementalLifetimeSavings(state, getters) {
    return _.get(getters.selectedCompany, 'incremental_lifetime_savings', 0);
  },
  lifetimeSavings(state, getters) {
    return _.get(getters.selectedCompany, 'lifetime_savings', 0);
  },
  lifetimeSavingsDate(state, getters) {
    return _.get(getters.selectedCompany, 'lifetime_savings_date');
  },
  isDemo(state) {
    const authStore = useAuthStore();
    return _.get(state, ['federationContext', 'demo'], false) || !!authStore.profile.claims.isForcedDemo;
  },
  isReseller(state, getters) {
    return getters.selectedCompany.is_reseller;
  },
  customerSetting(state, setting) {
    return state.customerSettings[setting];
  },
  isSettingEnabled: state => setting => {
    // eslint-disable-next-line camelcase
    return state.customerSettings[setting]?.setting_value === 'true';
  },
};

const actions = {
  async initializeCustomer(context) {
    const currentCustomers = context.state.customers;
    const currentCompanyId = context.state.selectedCompanyId;

    if (_.some(currentCustomers) && currentCompanyId) {
      return;
    }

    let customers;
    const response = await customerService.getCustomers();
    customers = response.data;

    if (context.getters.isDemo) {
      customers = maskCustomers(customers);
    }

    context.commit('SET_CUSTOMERS', customers);
    const customerIds = _.map(customers, 'id');
    const selectedCompanyId = currentCompanyId;
    if (selectedCompanyId && _.includes(customerIds, selectedCompanyId)) {
      context.commit('SET_SELECTED_COMPANY_ID', selectedCompanyId);
    } else {
      context.commit('SET_SELECTED_COMPANY_ID', _.head(customerIds));
    }

    await context.dispatch('loadCustomerSettings');
  },
  async addCompany(context, companyName) {
    const { data: customer } = await customerService.addCustomer(companyName);
    const { data: customers } = await customerService.getCustomers();
    context.commit('SET_CUSTOMERS', customers);
    context.commit('SET_SELECTED_COMPANY_ID', customer.id);
  },
  async switchCompany(context, selectedCompanyId) {
    context.commit('SET_SELECTED_COMPANY_ID', selectedCompanyId);
    await context.dispatch('initializeCustomer');
    await context.dispatch('aws/reload', null, { root: true });
    await context.dispatch('gcp/reload', null, { root: true });
    const azureStore = useAzureStore();
    await azureStore.reload();
    await context.dispatch('nav/initialize', null, { root: true });
  },
  async loadCustomerSettings(context) {
    const settings = await customerService.getCustomerSettings();
    context.commit('SET_CUSTOMER_SETTINGS', settings);
  },
  resetCustomer(context) {
    context.commit('RESET_CUSTOMER');
  },
  async subscribe(context, { tokenId, cardholderName, companyAddress }) {
    try {
      await customerService.subscribe(
        tokenId || null,
        cardholderName || null,
        companyAddress.addressLine1,
        companyAddress.addressLine2,
        companyAddress.addressCity,
        companyAddress.addressState,
        companyAddress.addressZip,
        companyAddress.addressCountry
      );
      context.commit('SET_SUBSCRIBED');
      analytics.track('Subscribe');
    } catch (e) {
      analytics.track('Subscribe Error');
      throw e;
    }
  },
  async refresh(context) {
    const { data } = await customerService.getCustomers();
    const customers = data;
    context.commit('SET_CUSTOMERS', customers);
  },
  async federate(context, payload) {
    const federationId = Math.floor(Math.random() * 1000000000);
    const { customerId, demo } = payload;
    context.commit('SET_SELECTED_COMPANY_ID', customerId);
    context.commit('SET_FEDERATION_CONTEXT', {
      federationId: federationId,
      demo: demo,
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
