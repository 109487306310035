import type { InjectionKey } from 'vue';
import type { Store } from 'vuex';

import { useStore } from 'vuex';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type State = any;

// recommended implementation by vuex - https://vuex.vuejs.org/guide/typescript-support.html
export const injectionKey: InjectionKey<Store<State>> & symbol = Symbol('Vuex Store');

// when using the vuex store from a vue component, use this
export function useVuexStore(): Store<State> {
  return useStore<State>(injectionKey);
}

// the one and only vuex store
let store: Store<State> | undefined;

// when using the vuex store from outside a vue component, use this
export function getVuexStore(): Store<State> {
  if (store === undefined) throw new Error('Vuex Store has not been setup yet');
  return store;
}

// call this one time so that modules can access the vuex store using getVuexStore
export function setVuexStore(value: Store<State> | undefined): void {
  // allow tests to clear the previously set value to avoid having to mock getVuexStore
  if (value === undefined) {
    store = value;
    return;
  }
  if (store === undefined) {
    store = value;
  } else {
    throw new Error('Vuex Store has already been setup. Only call this once!');
  }
}
