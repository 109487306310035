import env from '@shared/utilities/environment';

const invokeWithLogging = (name, args, fn) => {
  if (env.isLocal()) {
    // eslint-disable-next-line no-console
    console.log(`calling ${name}`, args);
    fn();
  } else {
    fn();
  }
};

const wrap = method => (...args) => {
  if (window.analytics) {
    invokeWithLogging(method, args, () => {
      window.analytics[method].apply(window.analytics, args);
    });
  }
};

export default {
  load: wrap('load'),
  ready: wrap('ready'),
  identify: wrap('identify'),
  group: wrap('group'),
  track: wrap('track'),
  page: wrap('page'),
};
