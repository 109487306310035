import type { State } from './store';

import { createStore } from 'vuex';

import * as Sentry from '@console/lib/sentry';
import modules from '@console/state/vuex/modules';
import { setVuexStore } from '@console/state/vuex/store';
import env from '@shared/utilities/environment';

// create the store when the module is imported
const vuexStore = createStore<State>({
  modules,
  strict: env.get('NODE_ENV') !== 'production',
  plugins: [Sentry.vuexPlugin],
});

setVuexStore(vuexStore); // one time initialization

export const store = vuexStore;
